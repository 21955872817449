/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, useRef, forwardRef } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Grid, Button, Go, PortableText } from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

const Wrapper = styled.div`
  position: relative;
  z-index: 30;
  overflow: hidden;
`;

const HeadingWrapper = styled.div`
  height: 70vw;
  display: flex;

  ${MEDIA_QUERIES.desktop} {
    height: 50vh;
    min-height: 320px;
    grid-column: 2 / span 10;
  }
`;

const HeadingContainer = styled.div`
  grid-column: 2 / span 10;
`;

const Heading = styled.h2`
  text-align: center;
  text-transform: uppercase;
`;

const ArticleWrapper = styled.div`
  position: relative;
  padding-bottom: 3rem;

  ${MEDIA_QUERIES.desktop} {
    padding-bottom: 5rem;
  }
`;

const Article = styled.article`
  grid-column: 1 / -1;

  ${MEDIA_QUERIES.desktop} {
    grid-column: 4 / span 6;
  }

  ${MEDIA_QUERIES.large} {
    grid-column: 5 / span 4;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  ${MEDIA_QUERIES.desktop} {
    margin-top: 3rem;
  }
`;

const ButtonCSS = `
  width: 100%;
  position: relative;

  ${MEDIA_QUERIES.desktop} {
    width: 12rem;
  }
`;

const HalfAndHalfBanner = ({
  data: {
    _rawBody,
    primaryColor,
    secondaryColor,
    topHeading,
    bottomHeading,
    button
  }
}) => {
  const { isDesktop } = useBreakpoint();

  const spoonRef1 = useRef();
  const spoonRef2 = useRef();
  const spoonRef3 = useRef();
  const spoonRef4 = useRef();
  const refs = [spoonRef1, spoonRef2, spoonRef3, spoonRef4];

  const [spoonCenter, setSpoonCenter] = useState([]);
  const [spoonDegs] = useState([245, 45, 145, 300]);

  const getCenter = (element) => {
    const { left, top, width, height } = element.getBoundingClientRect();
    return { x: left + width / 2, y: top + height / 2 };
  };

  const rotateSpoon = ({ clientX, clientY }) => {
    refs.forEach((ref, refIndex) => {
      if (!spoonCenter?.[0]) return;

      const angle = Math.atan2(
        clientY - spoonCenter[refIndex].y,
        clientX - spoonCenter[refIndex].x
      );
      ref.current.style.transform = `rotate(${spoonDegs[refIndex] + angle}rad)`;
    });
  };

  useEffect(() => {
    if (
      !spoonRef1?.current ||
      !spoonRef2?.current ||
      !spoonRef3?.current ||
      !spoonRef4?.current
    ) {
      return;
    }

    const centers = [];

    refs.forEach((ref) => {
      const center = getCenter(ref.current);
      centers.push(center);
    });

    setSpoonCenter(centers);
  }, [spoonRef1, spoonRef2, spoonRef3, spoonRef4]);

  return (
    <Wrapper
      onMouseMove={(e) => {
        if (!isDesktop) {
          return;
        }

        rotateSpoon(e);
      }}
    >
      <HeadingWrapper
        css={css`
          align-items: flex-end;
          padding-bottom: 1.5rem;
          background-color: ${primaryColor?.hex || `var(--color-black)`};
        `}
      >
        <Grid>
          <HeadingContainer>
            <Heading
              className={isDesktop ? `h1` : `h2`}
              css={css`
                color: ${secondaryColor?.hex || `var(--color-gray)`};
              `}
            >
              {topHeading}
            </Heading>
          </HeadingContainer>
        </Grid>
      </HeadingWrapper>

      <HeadingWrapper
        css={css`
          background-color: ${secondaryColor?.hex || `var(--color-white)`};
          padding-top: 1rem;
        `}
      >
        <Grid>
          <HeadingContainer>
            <Heading
              className={isDesktop ? `h1` : `h2`}
              css={css`
                color: ${primaryColor?.hex || `var(--color-gray)`};
              `}
            >
              {bottomHeading}
            </Heading>
          </HeadingContainer>
        </Grid>
      </HeadingWrapper>

      <ArticleWrapper
        css={css`
          background-color: ${secondaryColor?.hex || `var(--color-white)`};
          color: ${primaryColor?.hex || `var(--color-black)`};
        `}
      >
        <Grid>
          <Article>
            <PortableText blocks={_rawBody} />

            <ButtonWrapper>
              <Go
                to={button?.link}
                _css={css`
                  width: 100%;

                  ${MEDIA_QUERIES.desktop} {
                    width: auto;
                  }
                `}
              >
                <Button
                  _css={css`
                    ${ButtonCSS}
                  `}
                  text={button?.text}
                />
              </Go>
            </ButtonWrapper>
          </Article>
        </Grid>

        <Spoon
          ref={spoonRef1}
          _css={css`
            position: absolute;
            top: -4rem;
            left: 4rem;
            width: 68.26px;
            height: 345.28px;
            fill: black;
            stroke: white;
            transform: rotate(${spoonDegs[0]}deg);
          `}
        />

        <Spoon
          ref={spoonRef2}
          _css={css`
            position: absolute;
            top: -4rem;
            right: 8rem;
            width: 68.26px;
            height: 345.28px;
            fill: black;
            stroke: white;
            transform: rotate(${spoonDegs[1]}deg);
          `}
        />

        <Spoon
          ref={spoonRef3}
          _css={css`
            position: absolute;
            bottom: 0;
            left: 12rem;
            width: 68.26px;
            height: 345.28px;
            fill: black;
            stroke: white;
            transform: rotate(${spoonDegs[2]}deg);
          `}
        />

        <Spoon
          ref={spoonRef4}
          _css={css`
            position: absolute;
            bottom: 0;
            right: 12rem;
            width: 68.26px;
            height: 345.28px;
            fill: black;
            stroke: white;
            transform: rotate(${spoonDegs[3]}deg);
          `}
        />
      </ArticleWrapper>
    </Wrapper>
  );
};

export default HalfAndHalfBanner;

export const query = graphql`
  fragment HalfAndHalfBannerFragment on SanityHalfAndHalfBanner {
    _type

    primaryColor {
      hex
      title
    }

    secondaryColor {
      hex
      title
    }

    topHeading

    bottomHeading

    _rawBody

    button {
      link
      text
    }
  }
`;

const Spoon = forwardRef(({ _css }, ref) => (
  <svg
    ref={ref}
    css={css`
      display: none;

      ${MEDIA_QUERIES.desktop} {
        display: block;
      }
      ${_css}
    `}
    viewBox="0 0 21 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9413 56.3307C14.7002 57.0355 14.3027 57.5521 13.8557 57.9293C16.0678 58.5594 17.6041 59.8401 18.5211 61.5821C19.7855 63.9841 19.7511 67.0445 19.1005 69.923C18.4444 72.8259 17.1252 75.7129 15.6022 77.8879C14.8409 78.9753 14.0063 79.9166 13.1479 80.5946C12.3068 81.2588 11.3369 81.7527 10.3273 81.7414L10.3385 80.7414L10.3273 81.7414C9.36953 81.7306 8.46385 81.1822 7.70486 80.5135C6.91674 79.8192 6.13842 78.8642 5.42158 77.7651C3.9871 75.5659 2.71363 72.6582 2.07942 69.7466C1.45127 66.8629 1.41366 63.8012 2.67342 61.4222C3.65096 59.5762 5.33253 58.2774 7.78503 57.7514C7.4297 57.3577 7.13627 56.8411 6.97793 56.1684L6.97792 56.1683C6.89336 55.809 6.81243 54.9862 6.72928 53.9194C6.64276 52.8093 6.54741 51.332 6.44793 49.5853C6.24889 46.0906 6.03236 41.5003 5.83614 36.5756C5.44395 26.7328 5.1316 15.5223 5.20422 9.04288L5.20517 8.95802L5.22041 8.87453C5.72092 6.13235 6.39791 4.34992 7.44437 3.28201C8.56183 2.14163 9.92565 1.98282 11.2418 1.99757C12.6652 2.0135 14.1129 2.42814 15.2829 3.60276C16.4339 4.75839 17.2005 6.53469 17.5259 9.041L17.535 9.11069L17.5342 9.18097C17.4579 15.9957 16.9581 27.3255 16.4103 37.1407C16.1362 42.0505 15.8497 46.5885 15.5974 50.001C15.4713 51.7064 15.3534 53.1361 15.2495 54.1925C15.1976 54.7199 15.1485 55.1617 15.1027 55.5014C15.0623 55.8018 15.013 56.1211 14.9413 56.3306L14.9413 56.3307Z"
      fill="inherit"
      stroke="inherit"
      strokeWidth="2"
    />
  </svg>
));
